// ========================================= Number grid
// ========================================= Used to make margin and padding classes
$NUMBERGRID: (4: 4px, 8: 8px, 16: 16px, 24: 24px, 32: 32px, 40: 40px, 48: 48px, 56: 56px, 64: 64px, 72: 72px, 80: 80px, 88: 88px, 96: 96px, 104: 104px, 112: 112px, 120: 120px, 160: 160px, 180: 180px, 240: 240px);
/* Generate Margin Attribute Styles */
//
@each $i in map-keys($NUMBERGRID) {
  .margin-top-#{$i} {
    margin-top: map-get($NUMBERGRID, $i) !important;
  }
  .margin-bottom-#{$i} {
    margin-bottom: map-get($NUMBERGRID, $i) !important;
  }
  .margin-left-#{$i} {
    margin-left: map-get($NUMBERGRID, $i) !important;
  }
  .margin-right-#{$i} {
    margin-right: map-get($NUMBERGRID, $i) !important;
  }
  .margin-#{$i} {
    margin: map-get($NUMBERGRID, $i) !important;
  }
}

//======================================= Padding spacing
@each $key in map-keys($NUMBERGRID) {
  .padding-bottom-#{$key} {
    padding-bottom: map-get($NUMBERGRID, $key);
  }
  .padding-top-#{$key} {
    padding-top: map-get($NUMBERGRID, $key);
  }
  .padding-left-#{$key} {
    padding-left: map-get($NUMBERGRID, $key);
  }
  .padding-right-#{$key} {
    padding-right: map-get($NUMBERGRID, $key);
  }
  .padding-#{$key} {
    padding: map-get($NUMBERGRID, $key);
  }
}

//======================================= Grid
.grid {
  margin-left: auto;
  margin-right: auto;
  display: block;
  flex: 1 1 0;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 calc(calc(#{$i} / 12) * 100%);
    flex-direction: column;
    width: calc(calc(#{$i} / 12) * 100%);
    max-width: calc(calc(#{$i} / 12) * 100%);
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.col-auto {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row {
  display: flex !important;
  flex-direction: row !important;
}
.flex{
  display: flex !important;
}
.pointer{
  cursor: pointer !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-center {
  align-items: center !important;
}

//======================================= end of Grid
.no-margin {
  margin: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

mat-icon.md-18  { font-size: 18px !important;height: 18px !important;    width: 18px !important; cursor: pointer;}
mat-icon.md-24 { font-size: 24px !important;height: 24px !important;    width: 24px !important; cursor: pointer;}
mat-icon.md-36 { font-size: 36px !important;height: 36px !important;    width: 36px !important; cursor: pointer;}
mat-icon.md-48 { font-size: 48px !important;height: 48px !important;    width: 48px !important; cursor: pointer;}
