/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* You can add global styles to this file, and also import other style files */
@import "./theme/variables";
@import "./theme/helper-classes";
@import "./theme/material";
@import "./theme/main";
@import "./theme/disco-variables.scss";
@import "./styles-variables.scss";

@font-face {
  font-family: "FontAwesome";
  src:
    url("../assets/font/fontawesome-webfont.ttf") format("truetype"),
    url("../assets/font/fontawesome-webfont.woff2") format("woff2"),
    url("../assets/font/fontawesome-webfont.woff") format("woff"),
    url("../assets/font/fontawesome-webfont.eot"),
    url("../assets/font/fontawesome-webfont.svg") format("svg");
  font-size: 18px;
  font-weight: bold;
}

:root .light {
  --buttons-color: white;
  --horizontal-facet-button: #1580b299;
}

.auth-account.mat-option {
  overflow: unset !important;
  height: unset !important;
  min-height: 48px !important;

  .mat-option-text {
    height: 100%;
    display: flex !important;
    overflow: unset !important;
    justify-content: flex-start;

    .accuont-admins {
      font-size: 12px;
      line-height: 14px;
      white-space: normal;
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-left: 4px;
    }
  }
}

.notifications-menu {
  max-height: 380px !important;
  min-width: 285px !important;
}

::ng-deep snack-bar-container.error-snackbar {
  background-color: rgb(124, 0, 0);
}

// AI Parsd Overide Colors

futuristic-layout fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
  background-color: var(--ai-light-purpule) !important;
  color: var(--ai-black) !important;
}

.fuse-vertical-navigation-header {
  .mainTitle {
    color: var(--ai-black) !important;
  }
}

.dark
  fuse-vertical-navigation-group-item
  > .fuse-vertical-navigation-item-wrapper
  .fuse-vertical-navigation-item
  .fuse-vertical-navigation-item-title-wrapper
  .fuse-vertical-navigation-item-title {
  color: var(--ai-black) !important;
}

body.light .mat-flat-button.mat-primary,
body.light .mat-raised-button.mat-primary,
body.light .mat-fab.mat-primary,
body.light .mat-mini-fab.mat-primary,
body .light .mat-flat-button.mat-primary,
body .light .mat-raised-button.mat-primary,
body .light .mat-fab.mat-primary,
body .light .mat-mini-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
// .mat-stroked-button .mat-button-wrapper>*,
// .mat-stroked-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary {
  background-color: var(--ai-dark-purpule) !important;
  color: var(--ai-white) !important;
}

.mat-stroked-button.mat-button-disabled{
  border-color: currentColor !important;
}

.mat-flat-button .mat-icon,
.mat-raised-button .mat-icon,
.mat-fab .mat-icon,
.mat-mini-fab .mat-icon,
body.light .mat-icon.mat-primary,
body .light .mat-icon.mat-primary {
  color: var(--ai-white) !important;
}

.mat-toolbar.mat-primary {
  background: var(--ai-black) !important;
  color: var(--ai-white) !important;
}

.mat-primary body.light .mat-progress-spinner circle,
body.light .mat-spinner circle {
  stroke: var(--ai-dark-gray);
}

.mat-form-field.mat-form-field-disabled.mat-form-field-appearance-outline
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-outline {
  color: var(--ai-black);
}

body.light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
body .light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--ai-black);
}

.fuse-vertical-navigation-header,
.fuse-vertical-navigation-footer {
  display: none;
}

fuse-vertical-navigation,
futuristic-layout .main-view-container,
futuristic-layout .report-main-grid,
futuristic-layout #pivot-chart,
futuristic-layout .k-chart {
  height: calc(100vh - var(--toolbar-height)) !important;
  min-height: calc(100vh - var(--toolbar-height)) !important;
  max-height: calc(100vh - var(--toolbar-height)) !important;
}

fuse-vertical-navigation-group-item
  .fuse-vertical-navigation-item-wrapper
  .fuse-vertical-navigation-item
  .fuse-vertical-navigation-item-title-wrapper
  .fuse-vertical-navigation-item-title {
  font-size: 16px;
  font-weight: 500;
}

.fuse-vertical-navigation-item-badge-content.ai-badge {
  background-color: var(--ai-dark-purpule);
  border-radius: 16px;
  padding: 0 2px;
  width: 32px;
  font-size: 10px !important;
  color: white;
  font-weight: 200 !important;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.repeated-sec {
  .item-group {
    display: flex;
    .center-button{
      display: flex;
      align-items: center;
    }
  }
  formly-repeat-section .flex-row {
    min-height: 100px;
  }
}
span.mat-select-min-line.ng-tns-c170-3.ng-star-inserted {
  padding-left: 8px;
}

.mat-input-element {
  caret-color: #343131;
}

.mat-select .mat-select-trigger .mat-select-value .mat-select-value-text > * {
  padding: 4px;
}

mat-slide-toggle {
  margin: 8px 0;
  display: block;
}

.loader-center {
  position: absolute;
  left: 45%;
  top: 45%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
}

mat-spinner {
  width: 100px; /* Very big spinner size */
  height: 100px;
  color: blue; /* Spinner color */
}



.dyn-table-save-button{
  background: var(--ai-dark2-purpule) !important;
  color: var(--ai-white) !important;
}

.dyn-table-remove-button{
  mat-icon{
    color: var(--ai-red-error) !important;
  }
}

.k-grid th {
  font-weight: 500 !important;
}

.resize-bar{
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--ai-dark-gray);
  width: 20px;
  position: absolute;
  right: 0;
  height: 100%;
  cursor: col-resize;
  mat-icon{
    position: absolute;
    right: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    cursor: col-resize;
    &:hover{
      cursor: col-resize;
    }
  }
}
