:root {
    --base-color: #6C00F8;
    --base-color-light: #6C00F8;
    --main-color: #ffffff;
    --dark-light: #f1f1f1;
    --dark-50: #fafafa;
    --dark-100: #f5f5f5;
    --dark-200: #eeeeee;
    --dark-300: #e0e0e0;
    --dark-400: #bdbdbd;
    --dark-500: #9e9e9e;
    --dark-600: #757575;
    --dark-700: #616161;
    --dark-800: #424242;
    --dark-900: #2c2c2c;
    --dark-1000: #161616;
    --dark-A100: #020202;
    --dark-A200: #eeeeee;
    --dark-A400: #bdbdbd;
    --text-color: #212121;
}
:root .dark {
    --base-color: #00aeec;
    --base-color-light: #cacaca;
    --dark: #212121;
    --main-color: #212121;
    --dark-light: #2e2e2e;
    --dark-50: #fafafa;
    --dark-100: #f5f5f5;
    --dark-200: #eeeeee;
    --dark-300: #e0e0e0;
    --dark-400: #bdbdbd;
    --dark-500: #9e9e9e;
    --dark-600: #757575;
    --dark-700: #616161;
    --dark-800: #424242;
    --dark-900: #2c2c2c;
    --dark-1000: #161616;
    --dark-A100: #ffffff;
    --dark-A200: #eeeeee;
    --dark-A400: #bdbdbd;
    --text-color: #bdbdbd;
}

:root .light {
    --base-color: #6C00F8;
    --base-color-light: #6C00F8;
    --main-color: #ffffff;
    --dark-light: #f1f1f1;
    --dark-50: #fafafa;
    --dark-100: #f5f5f5;
    --dark-200: #eeeeee;
    --dark-300: #e0e0e0;
    --dark-400: #bdbdbd;
    --dark-500: #9e9e9e;
    --dark-600: #757575;
    --dark-700: #616161;
    --dark-800: #424242;
    --dark-900: #2c2c2c;
    --dark-1000: #161616;
    --dark-A100: #020202;
    --dark-A200: #eeeeee;
    --dark-A400: #bdbdbd;
    --text-color: #212121;
}

:root .sett {
    --base-color: #F0F0FA;
    --base-color-light: #F0F0FA;
    --main-color: #ffffff;
    --dark-light: #f1f1f1;
    --dark-50: #fafafa;
    --dark-100: #f5f5f5;
    --dark-200: #eeeeee;
    --dark-300: #e0e0e0;
    --dark-400: #bdbdbd;
    --dark-500: #9e9e9e;
    --dark-600: #757575;
    --dark-700: #616161;
    --dark-800: #424242;
    --dark-900: #2c2c2c;
    --dark-1000: #161616;
    --dark-A100: #020202;
    --dark-A200: #eeeeee;
    --dark-A400: #bdbdbd;
    --text-color: #212121;
}
