$toolbar-breakpoint: 600px;

$fa-font-path: "../node_modules/font-awesome/fonts";

$link-hover-decoration: none;

// breakpoints are dublicated under user-settings.component
$grid-breakpoints: (
  xs: 360px,
  sm: 550px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

$primary: #010200;
$accent: #59a000;
$warn: #78be20;

:root {
  --ai-gray: #FCFCFE;
  --ai-dark-gray: #909BA9;
  --ai-nigh-gray: #595B60;
  --ai-pewter-gray: #9FA2AA;
  --ai-light-gray: #F1F1F1;
  --ai-bg-gray: #FAFAFA;
  --ai-purpule: #6C00F8;
  --ai-light-purpule: #F0F0FA;
  --ai-light-purpule2: #DAD6FF;
  --ai-light-purpule3: #F5F4FF;
  --ai-light-purpule4: #cbc5ff;
  --ai-dark-purpule: #6C00F8;
  --ai-black: #161616;
  --ai-white: #ffffff;
  --ai-green: #D6F5DB;
  --ai-bg-light-gray: #FFFFFF;
  --ai-sidebar-item-hover: #e9d9ff;

  --ai-red: #FF0000;
  --ai-pink: #ffc9c9;
  --ai-orange-password: #FFA500;
  --ai-yellow: #FFFF00;
  --ai-green-password: #00FF00;
  --ai-blue: #0000ff;

  --ai-green-success: #198754;
  --ai-red-error: #ED4337;
  --ai-orange: #F5D9BC;
  --ai-dark-orange: #ca983d;
  --ai-brown: #663300;
  --ai-light-blue: #D6DBF5;
  --ai-dark-blue: #586ACC;
  --ai-dark-green: #365E3D;
  --ai-dark2-purpule: #682AB7;
  --ai-white: #FFFFFF;
  --toolbar-height: 110px;

  --table-background-color: #f5f5f5;
}
